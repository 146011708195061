import React from 'react'
import { Link } from "gatsby"

const ServiceItem = ({ icon, title, content, cols, link, children }) => {
    return (
        <div className={'services__item col-md-' + cols}>
            { icon &&
                <img className="services__item__icon" src={icon} alt={title} />}

            { title &&
                <h3 className="services__item__title">
                    {title}
                </h3>}

            { content &&
                <p className="services__item__content">
                    {content}
                </p>
            }

            { link &&
                <Link to={link} />
            }

            {children}
        </div>
    )

}

export default ServiceItem