import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import TitleBlock from "../components/title-block/title-block"
import ServiceItem from "../components/services/service"

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <TitleBlock title="Services" subtitle="What we offer" />
    <div className="container">
      {/* <h2>
        What we do
    </h2> */}
      <p>At RPM accounting we provide a wide variety of services including but not limited to:</p>
      <div className="row">
        <ServiceItem title={'Bookkeeping / Administration'} cols={12} >
          <p>We're focused on providing you with New Zealand's most efficient bookkeeping service. Save your business time and money with our specialised bookkeeping systems and give yourself instant peace of mind knowing your books are meticulously taken care of.</p>
          <p><strong>Services:</strong></p>
          <div className="row">

            <div className="col-md-4">
              <ul>
                <li>Accounts receivable and Accounts payable</li>
                <li>We can send out sales invoices and pay your bills for you</li>
                <li>Bank reconciliations and Data Entry</li>
                <li>We provide affordable services to code and reconcile your bank transactions along with providing monthly bank reconciliation reports</li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul>
                <li>Xero Software Training</li>
                <li>We assist with Xero accounting system setups and training and offer services to run your xero accounts so you don’t have to worry about it.</li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul>
                <li>Company Structures</li>
                <li>Registering you at the companies office hassle free.</li>
                <li>IRD Liaison and 24/7 Support</li>
              </ul>
            </div>
          </div>
          <ul>

          </ul>
          <p>Let us handle your queries from IRD and set up a manageable payment schedule for you. We will handle your queries like its our own with fast replies and involvement of a member of senior staff in all replies.</p>
        </ServiceItem>
        <ServiceItem title={'Cashflow Forecasting'} cols={4}>
          <p>A cash flow forecast is essential for your business planning. Along with a budget, it's one of the best business tools you can have as it allows you to set financial targets and measure your performance.<br /><br /> It ensures that you will always have enough money for the things you need and the things that are important to you. Following a budget or spending plan will also keep you out of debt or help you work your way out of debt if you are currently in debt.</p>
        </ServiceItem>
        <ServiceItem title={'Annual Accounting'} cols={4} >
          <p>Monthly, quarterly or annual accounts which provide essential information on the financial health of your business, so that you can focus on what you do best.</p>
          <ul>
            <li>Prepare financial Statements-Balance sheet, Profit and loss and annual accounts.</li>
            <li>File with IRD-helping you manage your Income tax obligations with our favourite IRD.</li>
            <li>Review your Accounts- We will review your  annual accounts via your xero account and bank statements</li>
          </ul>
        </ServiceItem>
        <ServiceItem title={'Rental Accounting'} cols={4} >
          <p>Want to minimise tax on rental income? Ready to buy your first investment property or already having a investment portfolio come see us we are equipped to help you.</p>
          <p><strong>Our expertise:</strong></p>
          <ul>
            <li>Tax on rental income</li>
            <li>Rental property tax returns</li>
            <li>Rental income tax compliance and requirements</li>
            <li>Investment property tax deductions</li>
            <li>Depreciation on rental properties</li>
            <li>Rental property tax returns</li>
            <li>Commercial property accounting</li>
            <li>Property tax laws</li>
          </ul>
        </ServiceItem>
        <ServiceItem title={'GST'} cols={4} >
          <p>Monthly, two monthly, six monthly and annual GST filing leave the small stuff to us, spend more time on business expansion and growth. We can offer accurate, timely assistance in  several areas, including:</p>
          <ul>
            <li>Registration queries</li>
            <li>Preparing GST returns</li>
            <li>Filing and adjusting GST returns</li>
            <li>IRD audit assistance</li>
          </ul>

        </ServiceItem>
        <ServiceItem title={'PAYE'} cols={4}>
          <p>Processing payroll for employees in NZ can be complex and time consuming. This means that paying your staff in New Zealand takes up time you could spend improving your business. We’ll deliver a payroll system tailored to suit the exact needs of your business.</p>
          <ul>
            <li>Set up new employees, process payroll and prepare payslips</li>
            <li>Annual Leave entitlements &amp; rate calculations</li>
            <li>PAYE calculations</li>
            <li>IRD filing and tax payments</li>
          </ul>

        </ServiceItem>
        <ServiceItem title={'FBT'} cols={4}>
          <p>Every employer who provides fringe benefits to their employees must prepare and file an FBT return. Here we come handy to file your FBT returns on a quarterly, income year or annual basis. It applies to things like :</p>
          <ul>
            <li>work vehicles available for personal use</li>
            <li>subsidies on gym memberships or insurance</li>
            <li>discounted goods and services.</li>
          </ul>
          <p>“Pay the fringe benefits freely let us pay the taxes on them and claim it back on yearly return”.</p>
        </ServiceItem>
      </div>
    </div>
  </Layout >
)

export default ServicesPage
